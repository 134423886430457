<template>
  <el-dialog
    v-model="model"
    width="30%"
    align-center
    destroy-on-close
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-openApi-key-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-openApi-key-dialog-header__title">
        {{ props.type === 'add' ? t('title.addOpenAPIkey') : t('title.editOpenAPIkey') }}
      </h4>
    </template>
    <el-form ref="openAPIFormRef" label-position="top" :model="openAPIForm" :rules="rules" @submit.prevent>
      <el-form-item prop="name" :label="t('common.name')">
        <el-input ref="deleteInputRef" v-model.trim="openAPIForm.name" placeholder="key name" />
      </el-form-item>
    </el-form>

    <template #footer>
      <elv-button
        type="primary"
        round
        width="112px"
        height="44px"
        class="elv-businessData-source-delete-dialog-footer__delete"
        :loading="loading"
        :disabled="!openAPIForm.name"
        @click="onSaveOpenApi"
        >{{ props.type === 'add' ? t('button.create') : t('button.save') }}</elv-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { isEmpty } from 'lodash-es'
import { ElMessage } from 'element-plus'
import timezone from 'dayjs/plugin/timezone'
import BusinessDataApi from '@/api/BusinessDataApi'
import { useEntityStore } from '@/stores/modules/entity'
import type { FormInstance, FormRules } from 'element-plus'

const props = defineProps({
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  },
  type: {
    type: String,
    default: 'add'
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()

const entityStore = useEntityStore()
const model = defineModel<boolean>({ required: true })
const openAPIForm = reactive({
  name: ''
})
const loading = ref(false)
const openAPIFormRef = ref<FormInstance>()
const emit = defineEmits(['onResetDataList', 'onCheckDialog'])

const rules = reactive<FormRules>({
  name: [{ required: true, message: 'Please enter the name', trigger: 'blur' }]
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

// eslint-disable-next-line no-unused-vars
const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onCheckDialog = () => {
  openAPIForm.name = ''
  emit('onCheckDialog')
}

const onSaveOpenApi = async () => {
  if (!openAPIFormRef.value) return
  await openAPIFormRef.value.validate(async (valid: boolean) => {
    if (valid) {
      try {
        loading.value = true

        if (props.type === 'add') {
          await BusinessDataApi.createOpenApiAccess(entityId.value, {
            name: openAPIForm.name
          })
        } else {
          await BusinessDataApi.editOpenApiAccess(entityId.value, props.currentData?.openApiAccessId, {
            name: openAPIForm.name
          })
        }
        emit('onResetDataList')
        ElMessage.success(t('message.success'))
        onCheckDialog()
      } catch (error: any) {
        ElMessage.error(error?.message)
        console.log(error)
      } finally {
        loading.value = false
      }
    }
  })
}

watch(
  [() => model.value, () => props.currentData],
  (value) => {
    if (value) {
      if (props.type === 'edit' && !isEmpty(props.currentData)) {
        openAPIForm.name = props.currentData.name
      } else {
        openAPIForm.name = ''
      }
    }
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-openApi-key-dialog {
  width: 620px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-account-source-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .el-input {
      width: 572px;
      height: 44px;
      border-radius: 4px;
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

      &:hover {
        border: 1px solid #4c7ad2;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
      }

      &.is_focus {
        border: 1px solid #4c7ad2;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
      }
    }

    .el-form-item {
      margin-bottom: 0;

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
