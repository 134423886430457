<template>
  <div class="elv-journal-integration-dataName-content">
    <p class="elv-journal-integration-dataName-content__title">{{ t('common.dataName') }}</p>
    <el-input v-model="model" @change="onChangeDataName" @input="onInputDataName" @blur="onChangeDataName" />
    <p v-if="showErrorInfo" class="elv-journal-integration-dataName-content__error">
      {{ model === '' ? t('message.dataNameEmptyInfo') : t('message.integrationDataNameInfo', { dataName: model }) }}
    </p>
    <div>
      <elv-button height="44" width="96" round type="primary" :loading="saveLoading" @click="onSaveDataName">{{
        props.businessDataTypeId === '' ? t('button.add') : t('button.save')
      }}</elv-button>
      <elv-button height="44" width="113" round type="info" :disabled="saveLoading" @click="onCancelSave">{{
        t('button.cancel')
      }}</elv-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { trim } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import BusinessDataApi from '@/api/BusinessDataApi'

const props = defineProps({
  businessDataTypeId: {
    type: String,
    default: ''
  }
})

const { t } = useI18n()
const route = useRoute()
const model = defineModel<string>({ required: true })

const showErrorInfo = ref(false)
const saveLoading = ref(false)

const emit = defineEmits(['onResetData', 'onCancelSave'])

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const onInputDataName = () => {
  if (model.value !== '' && showErrorInfo.value) showErrorInfo.value = false
}

const onChangeDataName = () => {
  console.log('model.value', model.value)
  if (model.value === '') {
    showErrorInfo.value = true
  } else {
    showErrorInfo.value = false
  }
}

const onAddDataName = async () => {
  try {
    showErrorInfo.value = false
    saveLoading.value = true
    await BusinessDataApi.createBusinessDataType(entityId.value, { name: trim(model.value) })
    ElMessage.success(t('message.success'))
    model.value = ''
    emit('onResetData')
  } catch (error: any) {
    console.error(error)
    if (error.code === 21000) {
      showErrorInfo.value = true
    } else {
      ElMessage.error(error.message)
    }
  } finally {
    saveLoading.value = false
  }
}

const onEditDataName = async () => {
  try {
    showErrorInfo.value = false
    saveLoading.value = true
    await BusinessDataApi.editBusinessDataType(entityId.value, props.businessDataTypeId, { name: trim(model.value) })
    ElMessage.success(t('message.editSuccess'))
    emit('onResetData')
  } catch (error: any) {
    console.error(error)
    if (error.code === 21000) {
      showErrorInfo.value = true
    } else {
      ElMessage.error(error.message)
    }
  } finally {
    saveLoading.value = false
  }
}

const onSaveDataName = () => {
  if (model.value === '') {
    showErrorInfo.value = true
    return
  }
  if (props.businessDataTypeId) {
    onEditDataName()
  } else {
    onAddDataName()
  }
}
const onCancelSave = () => {
  showErrorInfo.value = false
  emit('onCancelSave')
}
</script>

<style lang="scss" scoped>
.elv-journal-integration-dataName-content {
  display: flex;
  width: 100%;
  background: #f9fafb;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;

  .elv-journal-integration-dataName-content__title {
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .elv-journal-integration-dataName-content__error {
    color: #eb3a30;
    font-family: 'Plus Jakarta Sans';
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
</style>
