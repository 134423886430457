<template>
  <el-dialog
    v-model="model"
    width="30%"
    align-center
    destroy-on-close
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-businessData-source-csv-dialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-businessData-source-csv-dialog-header__title">
        {{ t('button.addCustomButton', { name: props.currentData?.name }) }}
      </h4>
    </template>
    <el-form
      ref="uploadFormRef"
      :model="uploadForm"
      :rules="rules"
      label-position="top"
      :style="{ 'margin-bottom': !analyze ? '24px' : 0 }"
    >
      <el-form-item :label="t('report.sourceName')" prop="name">
        <el-input v-model="uploadForm.name" />
      </el-form-item>

      <el-form-item>
        <el-upload
          v-show="showUpload"
          ref="uploadRef"
          class="elv-businessData-source-csv-dialog-upload"
          drag
          multiple
          :show-file-list="false"
          :headers="{ Authorization: authorization }"
          :action="`${VITE_API_URL}/entity/${entityId}/businessDataType/${props.currentData?.businessDataTypeId}/uploadBusinessDataCsv`"
          :before-upload="beforeCSVUpload"
          @error="onUploadError"
          @success="onUploadSuccess"
        >
          <SvgIcon name="source-upload" class="elv-source-upload__icon" width="32" height="32" />
          <div class="elv-source-upload__text">
            {{ t('report.dragUploadOrBrowse') }}<br /><span>{{ t('report.uploadCSVMaxSize') }}</span>
          </div>
        </el-upload>
        <div
          v-if="!showUpload || analyze"
          class="elv-source-upload-parsing-container"
          :class="{ 'is-error': uploadError }"
        >
          <div class="elv-source-upload-content">
            <SvgIcon v-if="analyze" name="sources-sync-done" width="32" height="32" />
            <img v-else-if="!uploadError" src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
            <SvgIcon v-else name="sources-sync-error" width="32" height="32" />
            <p class="elv-source-upload-parsing-title">
              {{
                analyze
                  ? t('report.uploadCompleted')
                  : uploadError
                    ? t('report.wrongDataFormat')
                    : t('report.uploading')
              }}
            </p>
            <p v-if="analyze" class="elv-source-upload-parsing-info">
              {{ t('common.totalLines') }}:&nbsp;{{ fileData?.count }}
            </p>
            <p v-else-if="!uploadError" class="elv-source-upload-parsing-info">
              {{ uploadFile?.name }} ({{ fileSize }})<span @click="onCancelUpload">{{ t('button.cancel') }}</span>
            </p>
            <p v-else class="elv-source-upload-parsing-info">
              {{ t('report.businessDataCSVFormatError', { name: props.currentData?.name })
              }}<span @click="onReuploadFile">{{ t('button.uploadAnotherFile') }}</span>
            </p>
          </div>
        </div>
      </el-form-item>
    </el-form>

    <div v-if="analyze" class="el-dialog__footer">
      <elv-button type="primary" round width="95" height="44" :loading="submitLoading" @click="onClickConnect">{{
        t('button.add')
      }}</elv-button>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { useCookies } from 'vue3-cookies'
import BusinessDataApi from '@/api/BusinessDataApi'
import { useEntityStore } from '@/stores/modules/entity'
import type { UploadRawFile, FormInstance, FormRules, UploadProps } from 'element-plus'

const props = defineProps({
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()
const route = useRoute()
const { cookies } = useCookies()
const entityStore = useEntityStore()
const model = defineModel<boolean>({ required: true })
const { VITE_API_URL } = import.meta.env

const uploadForm: any = reactive({
  name: '',
  file: '',
  platformId: '',
  businessDataTypeId: ''
})

const uploadRef = ref()
const submitLoading = ref(false)
const uploadError = ref(false) // 上传失败
const analyze = ref(false) // 是否解析完成
const showUpload = ref(true) // 是否显示上传组件
const uploadFormRef = ref<FormInstance>()
const uploadFile: any = ref<UploadRawFile>()
const fileData: any = ref({
  businessDataFileId: '0',
  count: 0,
  fileName: ''
})

const rules = reactive<FormRules>({
  name: {
    required: true,
    trigger: 'blur',
    message: t('message.pleaseEnterSourceName')
  },
  Upload: {
    required: true,
    trigger: 'blur'
  },
  address: {
    required: true,
    trigger: 'blur'
  }
})
const emit = defineEmits(['onResetData'])

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const fileSize = computed(() => {
  const units = ['bytes', 'kb', 'm']
  const k = 1024
  const i = Math.floor(Math.log(uploadFile.value?.size) / Math.log(k))
  // eslint-disable-next-line no-unsafe-optional-chaining
  const size = `${parseFloat((uploadFile.value?.size / k ** i).toFixed(2))}${units[i]}`
  return size
})

const authorization = computed(() => {
  return `Bearer ${cookies.get('elv-app-token')}`
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onReuploadFile = () => {
  analyze.value = false
  uploadError.value = false
  showUpload.value = true
  uploadForm.file = ''
  fileData.value = {
    entityFileId: 0,
    totalLine: 0,
    unrecognizableLine: 0,
    recognizedLine: 0,
    preview: {
      bankAccountList: [],
      walletAccountList: [],
      marketAccountList: [],
      addressList: []
    }
  }
  uploadForm.platformId = ''
  uploadFile.value = undefined
}

const onAddCSVSource = async () => {
  try {
    const params: any = {
      name: uploadForm.name,
      businessDataFileId: fileData.value.businessDataFileId
    }
    await BusinessDataApi.addBusinessDataSource(entityId.value, props.currentData?.businessDataTypeId, params)
    uploadFormRef.value?.resetFields()
    emit('onResetData')
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    submitLoading.value = false
  }
}

const onClickConnect = async () => {
  if (!uploadFormRef.value) return
  await uploadFormRef.value.validate((valid: boolean) => {
    if (valid) {
      submitLoading.value = true
      if (
        ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
        !currentEntityPermission.value?.journal?.create
      ) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      onAddCSVSource()
    }
  })
}

const beforeCSVUpload: UploadProps['beforeUpload'] = (rawFile: File) => {
  let status = true
  return uploadFormRef.value
    ?.validateField('name', (isValidate: boolean): any => {
      if (isValidate) {
        if (rawFile.type !== 'text/csv') {
          ElMessage.error('Please upload CSV file!')
          status = false
        }
        const k = 1024
        if (rawFile.size / k / k > 100) {
          ElMessage.error('The file size can not exceed 100m!')
          status = false
        }
        if (status) uploadFile.value = rawFile
      } else {
        status = false
      }
      return status
    })
    .then(() => {
      if (uploadFile.value) {
        uploadError.value = false
        showUpload.value = false
        analyze.value = false
      }
      return status
    })
}

const onUploadError: UploadProps['onError'] = (error: any) => {
  uploadError.value = true
  showUpload.value = false
  analyze.value = false
  const err = JSON.parse(error.message)
  ElMessage.error(err?.data?.message)
}

const onUploadSuccess: UploadProps['onSuccess'] = (response: any) => {
  analyze.value = true
  fileData.value = response.data
}

const onCancelUpload = () => {
  onReuploadFile()
  uploadRef.value?.abort()
}

const resetFrom = () => {
  onReuploadFile()
  uploadFormRef.value?.resetFields()
}

defineExpose({
  resetFrom
})

watchEffect(() => {
  onReuploadFile()
  uploadForm.name = ''
  uploadForm.file = ''
  uploadForm.businessDataTypeId = props.currentData?.businessDataTypeId
})
</script>

<style lang="scss">
.elv-businessData-source-csv-dialog {
  width: 620px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-account-source-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .el-input {
      width: 572px;
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #636b75;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    .elv-businessData-source-csv-dialog-upload {
      position: relative;

      .el-upload-dragger {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        width: 572px;
        height: 200px;
        border: 1.5px dashed #dde1e6;
        padding: 59.5px 10px;
        transition: all 0.2s;

        &:hover {
          border-color: #1753eb;

          .elv-source-upload__icon {
            fill: #1753eb;
          }

          .elv-source-upload__text {
            color: #636b75;
          }
        }
      }

      .elv-source-upload__icon {
        fill: #aaafb6;
        margin-bottom: 16px;
        transition: all 0.2s;
      }

      .elv-source-upload__text {
        width: 232px;
        height: 33px;
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #aaafb6;
        transition: all 0.2s;

        span {
          font-weight: 400;
          font-size: 11px;
          line-height: 14px;
          zoom: 0.91;
        }
      }

      .el-upload__tip {
        display: flex;
        align-items: center;
        position: absolute;
        margin-top: 0px;
        left: 0;
        top: 0;
        height: 14px;
        line-height: 14px;

        p {
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 11px;
          color: #636b75;

          span {
            text-decoration-line: underline;
            cursor: pointer;
            color: #1753eb;
          }
        }
      }
    }

    .elv-source-upload-parsing-container {
      position: relative;

      .elv-source-upload-content {
        @keyframes upload-loading-rotate {
          to {
            transform: rotate(360deg);
          }
        }
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        width: 572px;
        height: 200px;
        border: 1.5px solid #dde1e6;
        background-color: #fff;
        border-radius: 8px;

        img {
          display: block;
          width: 32px;
          height: 32px;
          animation: upload-loading-rotate 2s linear infinite;
        }

        .elv-source-upload-parsing-title {
          font-family: 'Plus Jakarta Sans';
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #1e2024;
          margin-bottom: 6px;
          margin-top: 16px;
        }

        .elv-source-upload-parsing-info {
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #636b75;

          span {
            cursor: pointer;
            text-decoration-line: underline;
            color: #5e85eb;
            margin-left: 8px;
          }
        }
      }

      .elv-source-upload-tip {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        margin-top: 0px;
        left: 0;
        top: 0;
        height: 14px;
        line-height: 14px;

        > div {
          color: #636b75;
          font-family: 'Plus Jakarta Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          margin-bottom: 8px;
        }

        p {
          font-family: 'Plus Jakarta Sans';
          font-weight: 500;
          font-size: 11px;
          color: #636b75;

          span {
            text-decoration-line: underline;
            cursor: pointer;
            color: #1753eb;
          }
        }
      }
    }

    .elv-source-upload-title-content {
      height: 16px;
      margin-left: 6px;

      &:hover {
        svg {
          fill: #b0b3bc;
        }
      }

      svg {
        fill: #d6d9e0;
      }
    }

    .elv-accounts-data-select-content {
      display: flex;
      align-items: flex-end;
      margin-bottom: 16px;

      .el-form-item {
        margin-bottom: 0px;
      }

      .elv-accounts-date-select {
        box-sizing: border-box;
        height: 44px;
        width: 282px;
        padding: 8px 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #ffffff;
        border: 1px solid #dde1e6;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        position: relative;
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition-property: border, box-shadow;

        &.is-disabled {
          cursor: not-allowed !important;
          background: #f9fafb;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
        }

        &.is-short {
          width: 178px;
        }

        > div {
          display: flex;
          flex-direction: column;

          p {
            font-family: 'Plus Jakarta Sans';
            font-weight: 400;
            font-size: 11px;
            line-height: 15px;
            color: #aaafb6;
            margin-bottom: 8px;
          }

          span {
            font-family: 'Plus Jakarta Sans';
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: #0e0f11;
          }
        }

        &:hover,
        &.is-focus {
          cursor: pointer;
          border: 1px solid #5e85eb;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);

          svg {
            fill: #5e85eb;
          }
        }

        svg {
          position: absolute;
          right: 12px;
          bottom: 12px;
          fill: #838d95;
          transition: transform 0.3s;
        }

        .elv-accounts-date-time-value {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }

      .elv-accounts-date-scope {
        color: #000;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
      }
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
